<template>
    <div class="vx-col md:w-1/1 w-full">
        <vx-card title="Billing">
          
            <vs-tabs v-model="selected.tab">
                <vs-tab @click="colorx = 'danger'" label="Waiting Approval">
                    <div class="con-tab-ejemplo">
                        <waiting-approval :selected="selected"  @changeTab="selectedTab"></waiting-approval>
                        <!-- <release-invoice :selected="selected"  @changeTab="selectedTab"></release-invoice> -->
                    </div>
                </vs-tab>
                <vs-tab @click="colorx = 'danger'" label="Complete">
                    <div class="con-tab-ejemplo">
                        <closed :selected="selected"  @changeTab="selectedTab"></closed>
                    </div>
                </vs-tab>
            </vs-tabs>
        </vx-card>
         
    </div>
</template>
<script>

import waitingApproval from "./waiting-approval/waiting-approval.vue"
import closed from "./closed/closed.vue";

export default {
    components:{
        'waiting-approval':waitingApproval,
        'closed':closed
    },
    data: () => ({
        selected : {
            territory: {},
            tab : 0,
            itemKit : {},
            isEdit:true
        }
    }),
    watch: {
        selected:{
            territory: function() {
                console.log("selected")
            },
        }
    },
    methods:{
         selectedTab(value,invoice,isEdit = false){
            //  this.selected.territory  = option
             this.selected.invoice = invoice
             console.log(this.selected.invoice)
             this.selected.tab = value
             this.selected.isEdit = isEdit
         }
    },
    mounted(){
        this.showOption()
    }
}
</script>
<style >
    .multiselect__tags {
        min-height: 32px;
        display: block;
        padding: 3px 40px 0 8px;
        border-radius: 5px;
        border: 1px solid #e8e8e8;
        background: #fff;
        font-size: 14px;
    }
    .multiselect__content-wrapper{
        z-index:100;
        background: #fff;
    }
    </style>